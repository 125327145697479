import * as React from "react";
import { Typography } from "@mui/material";
import { Button } from "gatsby-theme-material-ui";

import SEO from "../components/SEO";
import Layout from "../components/Layout";

const NotFoundPage = () => (
    <Layout title="Page not found">
        <Layout.Content
            BoxProps={{
                py: 14
            }}
        >
            <Typography
                variant="h1"
                component="h1"
                fontSize="1.875rem"
                fontWeight="500"
                sx={{ mb: 2 }}
            >
                Sorry about this...
            </Typography>
            <Typography sx={{ mb: 2 }}>
                The issue has been logged. In the meantime, let’s get you back to the homepage so
                you can have another try.
            </Typography>
            <Button color="secondary" to="/">
                Go back to homepage
            </Button>
        </Layout.Content>
    </Layout>
);

export const Head = () => <SEO noIndex={true} />;

export default NotFoundPage;
